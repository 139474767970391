#overlay {
  z-index: 90;
  position: absolute;
  background-color: #000;
  //background-image: url(../img/overlay.png)
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  //background-size: cover !important
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  overflow-y: hidden;
  opacity: .8; }

#modal_menu {
  width: 100%;
  height: 100vh;
  border-radius: 5px;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 99;
  top: 0;
  justify-content: center;

  .main_close {
    width: 36px;
    height: 36px;
    cursor: pointer;
    display: block;
    color: #ffffff;
    font-size: 45px;
    font-weight: 400;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 45px; }

  .nav {
    position: relative;
    text-align: center;

    .nav_li+.nav_li {
      margin-top: 30px;

      @include r(750) {
        margin-top: 45px; } }

    .nav_li {
      color: #FFFFFF;
      text-transform: uppercase;
      @include font;
      font-size: 22px;
      font-weight: 700;

      @include r(500) {
        font-size: 18px; }

      a:hover {
        color: #bababa !important; } }

    .nav_active {
      color: #bababa;
      position: relative; } } }

#modal_callback {
  display: none; }

.close-call {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer; }

.callback-col {
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: #000000;
  padding: 40px;
  border-radius: 5px;

  @include r(550) {
    padding: 40px 15px; }

  .title-ithem {
    @include font;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 25px;
    text-align: center;

    @include r(550) {
      font-size: 22px;
      line-height: 26px; } }

  .text-ithem {
    @include font;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 27px;
    text-align: center; }

  .form-call {
    display: flex;
    flex-direction: column;
    width: 100%;

    .form-inp {
      height: 50px;
      text-align: center;
      font-size: 16px;
      color: #898989;
      margin-bottom: 20px; }

    .form-sub {
      height: 50px;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #0b327d; } } }
