// FOOTER START
.footer {
  background-color: #0b327d;

  .copy-right {
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    @include r(750) {
      font-size: 14px; } } }
// FOOTER START
