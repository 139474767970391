.our-work {
  background-color: #1a1a1a;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-image: url(../img/our-work_bg.png);

  .our-work_container {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .our-work_row {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } } }

.col-hid {
  @include r(767) {
    display: none; } }

.our-work_row {
  .our-work_col {
    width: 31%;
    margin-bottom: 35px;
    margin-left: 12px;
    margin-right: 10px;
    position: relative;
    z-index: 3;
    &:hover {
      transform: scale(1.02); }

    @include r(980) {
      width: 47%; }

    @include r(767) {
      width: 95%;
      margin: auto;
      margin-bottom: 15px; }

    .our-work_img {
      width: 100%;
      height: 100%;

      img {
        width: 100%; } }

    .zoom {
      position: absolute;
      z-index: 4;
      bottom: 0;
      right: 0;
      width: 35px;
      height: 35px;
      display: block;
      background-color: #0b327d;
      cursor: pointer;
      display: none;

      .fas {
        font-weight: 900;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px; } } } }
