.about-us {
  background-color: #121313;
  overflow: hidden;

  .us-container {
    padding: 50px 0;
    background-position-x: center!important;
    background-repeat: no-repeat!important;
    background: url(../img/home-about_bg.png);
    margin: auto;

    .section-title {
      //max-width: 360px
      margin: auto;
      text-align: center; }

    .about-content_wrap {
      width: 100%;
      margin-top: 25px;
      display: flex; }

    .prof_wrap {
      margin-top: 35px; } } }

.about-content_wrap {
  @include r(1024) {
    flex-direction: column; }

  .col-flex {
    display: flex;
    flex-wrap: wrap;

    @include r(1024) {
      margin: auto;
      margin-left: 29%;
      margin-bottom: 35px; }

    @include r(860) {
      width: 80%;
      margin-left: 15%; } }

  .right-col {
    @include r(1024) {
      width: 90%;
      margin: auto; } }

  .col-wrap {
    width: 50%;
    padding: 0 10px;

    @include r(550) {
      flex-direction: column; }

    .romb-bloc {
      width: 50%;
      position: relative;
      margin-top: 25px;

      @include r(550) {
        width: 100%;
        margin-bottom: 50px; }

      .romb-img {
        position: absolute;
        top: -15px;
        z-index: 2; }

      .romb-title {
        @include font;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 15px;
        z-index: 4;
        position: relative;
        margin-left: 60px; }

      .romb-subtitle {
        @include font;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        z-index: 4;
        position: relative;
        margin-left: 60px; } }

    .us-col_title {
      @include font;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 40px; }

    .us-col_subtitle {
      @include font;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
      line-height: 20px; } } }

.prof_wrap {

  .reverse {
    flex-direction: row-reverse; }

  .prof_row {
    display: flex;
    margin-bottom: 25px;

    @include r(1024) {
      flex-direction: column; }

    .col_prof {
      padding: 15px;
      width: 50%;

      @include r(1024) {
        width: 75%;
        margin: auto; }

      @include r(768) {
        width: 95%; }

      .list {
        display: flex; }

      .prof_img {
        width: 100%; }

      .prof_title {
        @include font;
        font-size: 28px;
        font-weight: 500;
        line-height: 21px;
        color: #ffffff;
        margin-bottom: 25px; }

      .prof_text {
        @include font;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 15px; }

      .count {
        @include font;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: #144cb7;
        margin-left: 25px;
        margin-right: 20px; } } } }
