.your-benefit {
  background-color: #0b327d;

  .your-benefit_container {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .benefit-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 50px;
      margin-bottom: 35px; }

    .section-subtitle {
      @include font;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      width: 75%;
      text-align: center;
      display: block;
      margin: auto;
      margin-bottom: 40px;

      @include r(750) {
        width: 85%; } }

    .btn-benefit {
      padding: 12px 10px;
      text-align: center;
      max-width: 420px;
      @include font;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #ffffff;
      cursor: pointer;
      margin: auto;
      display: block; } } }

.benefit-row {
  .benefit-col {
    width: 30.5%;
    padding: 25px 40px;
    background-color: #24478a;
    text-align: center;
    margin-bottom: 30px;
    margin-left: 12px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      transform: scale(1.02); }

    @include r(950) {
      width: 47%; }

    @include r(750) {
      width: 85%;
      margin: auto;
      margin-bottom: 15px; }

    @include r(420) {
      padding: 20px 15px;
      width: 95%; }

    .benefit-ico {
      width: 125px;
      margin: auto; }

    .benefit-title {
      @include font;
      font-size: 22px;
      font-weight: 600;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 20px; }

    .benefit-subtitle {
      @include font;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff; } } }




