.btn-s {
  @include font;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding: 15px 0;
  cursor: pointer; }

.index {
  background: url(../img/home-main_bg.png); }

.nano {
  background: url(../img/nano-main_bg.png); }

.bron {
  background: url(../img/bron-main_bg.png); }

.tonirovka {
  background: url(../img/tonirovka-main_bg.png); }

.polirovka {
  background: url(../img/polirivka-main_bg.png); }

.himchistka {
  background: url(../img/himchistka-main_bg.png); }

.antigravii {
  background: url(../img/antigravii-main_bg.png); }

.home-main {
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  padding-top: 200px;

  .home_content {
    max-width: 970px; }

  .nano_content {
    max-width: 1170px; }

  .content-wrap {
    width: 100%;
    padding: 0 20px;
    margin: auto;
    padding-bottom: 70px;

    .back_home {
      @include font;
      font-size: 14px;
      line-height: 48px;
      color: #ffffff;
      margin-bottom: 35px;
      display: block;

      @include r(550) {
        margin-top: 100px; }

      img {
        margin-right: 10px; } }

    .home-title {
      @include font;
      font-size: 32px;
      line-height: 48px;
      color: #ffffff;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 365px;

      @include r(768) {
        margin-bottom: 200px; }

      @include r(550) {
        margin-top: 140px;
        margin-bottom: 45px;
        font-size: 27px; } }

    .nano-title {
      @include font;
      font-size: 32px;
      line-height: 48px;
      color: #ffffff;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 60px;

      //+r(768)
      //  margin-bottom: 200px

      @include r(550) {
        margin-top: 35px;
        margin-bottom: 45px;
        font-size: 27px; } } } }

.nav-button_wrap {

  .button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include r(1024) {
      display: none; }

    .btn-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 280px;
      height: 45px;
      background: rgba(18, 18, 18, .6);
      -webkit-transform: skew(-20deg);
      -moz-transform: skew(-20deg);
      -o-transform: skew(-20deg);
      transform: skew(-20deg);
      margin-bottom: 20px;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        .title {
          font-size: 17px; } }

      .title {
        @include font;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        -webkit-transform: skew(20deg);
        -moz-transform: skew(20deg);
        -o-transform: skew(20deg);
        transform: skew(20deg); } } }

  .button-callbac {
    display: flex;
    justify-content: space-between;

    @include r(650) {
      flex-direction: column; }

    .btn-blu {
      background-color: #0b327d;
      width: 48%;
      display: block;
      text-align: center;

      @include r(650) {
        margin: auto;
        margin-bottom: 15px;
        max-width: 420px;
        width: 100%; } }

    .btn-ops {
      border: 1px solid #ffffff;
      width: 48%;
      display: block;
      text-align: center;

      @include r(650) {
        margin: auto;
        max-width: 420px;
        width: 100%; } } } }

.list_home {
  margin-left: 30px;

  @include r(550) {
    margin-left: 0; }

  .button-call {
    display: flex;
    justify-content: space-between;
    margin-top: 110px;

    @include r(650) {
      flex-direction: column; }

    .btn-blu {
      background-color: #0b327d;
      width: 420px;
      display: block;
      text-align: center;
      color: #FFFFFF;
      @include font;
      font-size: 16px;
      text-transform: uppercase;
      padding: 15px;

      @include r(650) {
        margin: auto;
        margin-bottom: 15px;
        max-width: 420px;
        width: 100%; } } }


  .ithem {
    display: flex;
    margin-bottom: 20px;

    .check {
      margin-right: 30px;
      width: 25px;
      height: 25px; }

    .ithem_list {
      @include font;
      display: block;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      width: 96%; } } }
