.come-us {
  background-color: #111212;
  position: relative;

  .come-us_container {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .come-us_contact {
      padding-top: 30px;
      margin-left: 20px;
      margin-top: 30px; } } }

.come-us_contact {
  @include r(750) {
    display: flex;
    flex-direction: column;
    margin-top: 0; }

  .contact-wrap {
    .text-ithem {
      @include font;
      color: #ffffff;
      line-height: 36px;
      @include r(750) {
        line-height: 25px;
        text-align: center; } }

    .ithem_22 {
      font-size: 22px;
      text-transform: uppercase;
      @include r(750) {
        font-size: 18px; } }

    .ithem_18_n {
      font-size: 18px;
      @include r(750) {
        font-size: 16px; } }

    .ithem_18_ttu {
      font-size: 18px;
      text-transform: uppercase;
      @include r(750) {
        font-size: 16px; } } }

  .map_content {
    position: absolute;
    height: 560px;
    width: 57%;
    right: 0;
    bottom: 0;

    @include r(750) {
      position: relative;
      height: 300px;
      width: 98%;
      margin-top: 25px; } } }


