.thanks {
  height: 100vh;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background: url(../img/thanks-main_bg.png);
  background-color: #1a1a1a;


  .thanks-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .thank_you {
      @include font;
      font-size: 32px;
      line-height: 48px;
      color: #ffffff;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 35px;

      @include r(550) {
        margin-top: 140px;
        font-size: 27px; } }

    .home {
      background-color: #0b327d;
      width: 420px;
      display: block;
      text-align: center;
      color: #FFFFFF;
      @include font;
      font-size: 16px;
      text-transform: uppercase;
      padding: 15px;
      margin: auto;

      @include r(650) {
        margin: auto;
        margin-bottom: 15px;
        max-width: 420px;
        width: 100%; } } }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0; } }
