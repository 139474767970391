// HEADER START
.header-container {
  position: absolute;
  width: 100%;
  top: 0;

  .nav {
    display: flex;
    justify-content: space-between;
    max-width: 1170px;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    padding: 0 20px;
    position: relative; } }

.mob_menu {
  display: none;

  @include r(1024) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    margin-top: 20px; }

  @include r(500) {
    margin-right: 20px!important; }

  .arr_m {
    background-color: #f2f2f2;
    height: 2px;
    width: 30px;
    margin-bottom: 6px;
    display: block; }

  .arr_d {
    background-color: #f2f2f2;
    height: 2px;
    width: 25px;
    margin-bottom: 6px;
    display: block; } }

.nav-cont {
  @include r(1024) {
    display: none; }

  .t-dwrap {
    @include font;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff; }

  .t-pwrap {
    @extend .t-dwrap;
    text-transform: uppercase; }

  .t-awrap {
    @include font;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 30px; } }

.nav-phone {

  .phone {
    @include font;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff; }

  .call-back {
    @include font;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 15px;
    display: block;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px  solid #ffffff; } }

.nav-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  img {
    @include r(1024) {
      width: 95px; } }

  @include r(550) {
    top: 140px; } }

// HEADER END
