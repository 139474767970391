// COMMON START
.m-a {
  margin: auto; }

.ml0 {
  margin-left: 0!important; }

.ml15 {
  margin-left: 15px; }

.mr15 {
  margin-right: 15px; }

.mb5 {
  margin-bottom: 5px!important; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb30 {
  margin-bottom: 30px!important; }

.mb45 {
  margin-bottom: 45px;
  @include r(750) {
    margin-bottom: 15px; } }

.container {
  max-width: 1190px;
  width: 100%;
  padding: 50px 15px;
  @include r(550) {
    padding: 25px 15px; } }

.section-title {
  @include font;
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background: url(../img/title_bg.png);
  padding: 0 15px;

  @include r(750) {
    font-size: 30px; }

  @include r(550) {
    padding: 0;
    font-size: 25px; } }

// COMMON END


input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease; }
input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease; }
input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease; }
input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease; }
input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }
input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }
input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }
input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }
