.the-trust {
  background-color: #0e0e0e;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-image: url(../img/our-work_bg.png);

  .the-trust_contaner {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .row-trust {
      padding: 40px 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include r(550) {
        padding: 40px; } } } }


.row-trust {
  .col-trust {
    width: 32%;
    cursor: pointer;
    &:hover {
      transform: scale(1.02); }

    @include r(850) {
      width: 49%; }

    @include r(550) {
      width: 100%;
      margin-bottom: 15px; }

    img {
      width: 100%; } } }
