.our-services {
  background-color: #1a1a1a;

  .services-container {
    padding: 50px 0;
    margin: auto;

    .section-title {
      max-width: 360px;
      margin: auto;
      text-align: center; }

    .service-row {} } }

.service-row {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;

  .col-service {
    background-color: #131414;
    width: 31%;
    padding-bottom: 40px;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      transform: scale(1.02); }

    @include r(950) {
      width: 47%; }

    @include r(750) {
      width: 85%;
      margin: auto;
      margin-bottom: 15px; }

    @include r(420) {
      padding: 20px 15px;
      width: 95%; }


    .service-img {
      width: 100%; }

    .services-ithem {
      padding: 0 10px;

      .service-title {
        @include font;
        font-size: 22px;
        font-weight: 600;
        color: #ffffff;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 25px;
        text-align: center; }

      .service-subtitle {
        @include font;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        text-align: center; } } } }


//#us_01, #us_02, #us_03, #us_04, #us_05, #us_06, #us_07, #us_08
//  opacity: 0

