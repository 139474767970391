.we-doing {
  background-color: #1a1a1a;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-image: url(../img/we-bg.png);

  .we-doing_container {
    padding: 50px 0;
    margin: auto;
    @include r(1300) {
      margin-left: 0!important;
      margin-right: 0!important;
      width: 100%;
      max-width: inherit; }

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .btn-call-us {
      padding: 12px 20px;
      text-align: center;
      max-width: 420px;
      font-family: Open Sans,sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      background-color: #0b327d;
      margin: 65px auto auto;
      cursor: pointer;

      @include r(450) {
        margin-left: 15px;
        margin-right: 15px; } }


    .we-subtitle {
      @include font;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      width: 72%;
      margin: auto;
      margin-top: 40px; }

    .animation-wrap {
      height: 720px;
      position: relative;
      margin-top: 40px; } } }

// animation elements
.we-doMob {
  display: none;
  @include r(1300) {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    img {
      width: 100%; } } }

.animation-wrap {
  @include r(1300) {
    display: none; } }

.cars {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%);
  z-index: 90; }


.elips {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 89; }

.elips-light {
  position: absolute;
  bottom: 0;
  left: 30%;
  transform: translate(-50%);
  z-index: 91; }

.car-stop {
  position: absolute;
  bottom: 135px;
  z-index: 92;
  left: 75px; }

.points {
  position: absolute;
  z-index: 94; }

//  position points
#we_001 {
  left: 100px;
  bottom: 115px; }

#we_002 {
  bottom: 235px;
  left: 180px; }

#we_003 {
  bottom: 250px;
  left: 430px; }

#we_004 {
  bottom: 165px;
  left: 555px; }

#we_005 {
  right: 445px;
  bottom: 245px; }

#we_006 {
  right: 200px;
  bottom: 205px; }

#we_007 {
  right: 100px;
  bottom: 160px; }


.animation-cont {
  width: 175px;
  position: absolute;
  z-index: 93;

  img {
    margin: auto;
    display: block;
    margin-bottom: 20px; }

  .cont-text {
    @include font;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase; } }

#we_01 {
  top: 210px;
  left: 8px; }

#we_02 {
  top: 95px;
  left: 200px; }

#we_03 {
  top: 235px;
  left: 350px; }

#we_04 {
  top: 62px;
  left: 483px; }

#we_05 {
  top: 192px;
  right: 388px; }

#we_06 {
  top: 105px;
  right: 215px; }

#we_07 {
  top: 201px;
  right: 15px; }
