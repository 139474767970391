.how-pay {
  background-color: #1a1a1a;

  .how-pay_container {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .pay-table {
      margin-top: 60px;

      @include r(825) {
        margin-top: 35px; } }

    .btn-order {
      padding: 12px 20px;
      text-align: center;
      max-width: 420px;
      font-family: Open Sans,sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      background-color: #0b327d;
      margin: 65px auto auto;
      cursor: pointer; } } }

.pay-table {

  .table-col {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #ffffff;

    @include r(825) {
      flex-direction: column; }

    .left-col {
      width: 40%;
      padding: 5px;

      @include r(825) {
        width: 100%; }

      .title-col {
        @include font;
        font-size: 22px;
        line-height: 37px;
        text-transform: uppercase;
        color: #fff;

        @include r(550) {
          font-size: 18px; } }

      .subtitle-col {
        @include font;
        font-size: 14px;
        color: #fff; } }

    .right-col {
      width: 60%;
      display: flex;

      @include r(825) {
        width: 100%; }

      .dflex {
        display: flex;
        justify-content: center;
        //align-items: center
        flex-direction: column; }

      .col-ithem {
        width: 25%;
        padding: 5px 20px;

        @include r(420) {
          padding: 5px; }

        .title-ithem_hidden {
          @include font;
          font-size: 18px;
          text-transform: uppercase;
          color: #969696;
          margin-top: 10px;
          margin-bottom: 10px;
          display: none;

          @include r(825) {
            display: block; }

          @include r(550) {
            font-size: 14px; } }

        .title-ithem {
          @include font;
          font-size: 18px;
          text-transform: uppercase;
          color: #fff;

          @include r(550) {
            font-size: 14px; } }

        .subtitle-ithem {
          @include font;
          font-size: 13px;
          color: #848484;

          @include r(925) {
            display: none; } } } } }

  .gley-line {
    border-bottom: 1px solid #434343;

    @include r(825) {
      display: none; } } }




