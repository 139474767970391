html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
a {
  color: inherit;
  text-decoration: none;
}

input[type="text"]:active, input[type="text"]:hover,
input[type="email"]:active,
input[type="email"]:hover,
input[type="tel"]:active,
input[type="tel"]:hover,
a:active,
a:hover {
  text-decoration: none;
}

textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

.m-a {
  margin: auto;
}

.ml0 {
  margin-left: 0 !important;
}

.ml15 {
  margin-left: 15px;
}

.mr15 {
  margin-right: 15px;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb45 {
  margin-bottom: 45px;
}

@media only screen and (max-width: 750px) {
  .mb45 {
    margin-bottom: 15px;
  }
}

.container {
  max-width: 1190px;
  width: 100%;
  padding: 50px 15px;
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 25px 15px;
  }
}

.section-title {
  font-family: Open Sans, sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background: url(../img/title_bg.png);
  padding: 0 15px;
}

@media only screen and (max-width: 750px) {
  .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .section-title {
    padding: 0;
    font-size: 25px;
  }
}

input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.header-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.header-container .nav {
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0 20px;
  position: relative;
}

.mob_menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .mob_menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .mob_menu {
    margin-right: 20px !important;
  }
}

.mob_menu .arr_m {
  background-color: #f2f2f2;
  height: 2px;
  width: 30px;
  margin-bottom: 6px;
  display: block;
}

.mob_menu .arr_d {
  background-color: #f2f2f2;
  height: 2px;
  width: 25px;
  margin-bottom: 6px;
  display: block;
}

@media only screen and (max-width: 1024px) {
  .nav-cont {
    display: none;
  }
}

.nav-cont .t-dwrap, .nav-cont .t-pwrap {
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}

.nav-cont .t-pwrap {
  text-transform: uppercase;
}

.nav-cont .t-awrap {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 30px;
}

.nav-phone .phone {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.nav-phone .call-back {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 15px;
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px  solid #ffffff;
}

.nav-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media only screen and (max-width: 1024px) {
  .nav-logo img {
    width: 95px;
  }
}

@media only screen and (max-width: 550px) {
  .nav-logo {
    top: 140px;
  }
}

.footer {
  background-color: #0b327d;
}

.footer .copy-right {
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: Open Sans, sans-serif;
}

@media only screen and (max-width: 750px) {
  .footer .copy-right {
    font-size: 14px;
  }
}

#overlay {
  z-index: 90;
  position: absolute;
  background-color: #000;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  overflow-y: hidden;
  opacity: .8;
}

#modal_menu {
  width: 100%;
  height: 100vh;
  border-radius: 5px;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 99;
  top: 0;
  justify-content: center;
}

#modal_menu .main_close {
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: block;
  color: #ffffff;
  font-size: 45px;
  font-weight: 400;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 45px;
}

#modal_menu .nav {
  position: relative;
  text-align: center;
}

#modal_menu .nav .nav_li + .nav_li {
  margin-top: 30px;
}

@media only screen and (max-width: 750px) {
  #modal_menu .nav .nav_li + .nav_li {
    margin-top: 45px;
  }
}

#modal_menu .nav .nav_li {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  #modal_menu .nav .nav_li {
    font-size: 18px;
  }
}

#modal_menu .nav .nav_li a:hover {
  color: #bababa !important;
}

#modal_menu .nav .nav_active {
  color: #bababa;
  position: relative;
}

#modal_callback {
  display: none;
}

.close-call {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.callback-col {
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: #000000;
  padding: 40px;
  border-radius: 5px;
}

@media only screen and (max-width: 550px) {
  .callback-col {
    padding: 40px 15px;
  }
}

.callback-col .title-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 25px;
  text-align: center;
}

@media only screen and (max-width: 550px) {
  .callback-col .title-ithem {
    font-size: 22px;
    line-height: 26px;
  }
}

.callback-col .text-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 27px;
  text-align: center;
}

.callback-col .form-call {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.callback-col .form-call .form-inp {
  height: 50px;
  text-align: center;
  font-size: 16px;
  color: #898989;
  margin-bottom: 20px;
}

.callback-col .form-call .form-sub {
  height: 50px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #0b327d;
}

.about-us {
  background-color: #121313;
  overflow: hidden;
}

.about-us .us-container {
  padding: 50px 0;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background: url(../img/home-about_bg.png);
  margin: auto;
}

.about-us .us-container .section-title {
  margin: auto;
  text-align: center;
}

.about-us .us-container .about-content_wrap {
  width: 100%;
  margin-top: 25px;
  display: flex;
}

.about-us .us-container .prof_wrap {
  margin-top: 35px;
}

@media only screen and (max-width: 1024px) {
  .about-content_wrap {
    flex-direction: column;
  }
}

.about-content_wrap .col-flex {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .about-content_wrap .col-flex {
    margin: auto;
    margin-left: 29%;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 860px) {
  .about-content_wrap .col-flex {
    width: 80%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 1024px) {
  .about-content_wrap .right-col {
    width: 90%;
    margin: auto;
  }
}

.about-content_wrap .col-wrap {
  width: 50%;
  padding: 0 10px;
}

@media only screen and (max-width: 550px) {
  .about-content_wrap .col-wrap {
    flex-direction: column;
  }
}

.about-content_wrap .col-wrap .romb-bloc {
  width: 50%;
  position: relative;
  margin-top: 25px;
}

@media only screen and (max-width: 550px) {
  .about-content_wrap .col-wrap .romb-bloc {
    width: 100%;
    margin-bottom: 50px;
  }
}

.about-content_wrap .col-wrap .romb-bloc .romb-img {
  position: absolute;
  top: -15px;
  z-index: 2;
}

.about-content_wrap .col-wrap .romb-bloc .romb-title {
  font-family: Open Sans, sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  margin-bottom: 15px;
  z-index: 4;
  position: relative;
  margin-left: 60px;
}

.about-content_wrap .col-wrap .romb-bloc .romb-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  z-index: 4;
  position: relative;
  margin-left: 60px;
}

.about-content_wrap .col-wrap .us-col_title {
  font-family: Open Sans, sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 40px;
}

.about-content_wrap .col-wrap .us-col_subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 20px;
}

.prof_wrap .reverse {
  flex-direction: row-reverse;
}

.prof_wrap .prof_row {
  display: flex;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1024px) {
  .prof_wrap .prof_row {
    flex-direction: column;
  }
}

.prof_wrap .prof_row .col_prof {
  padding: 15px;
  width: 50%;
}

@media only screen and (max-width: 1024px) {
  .prof_wrap .prof_row .col_prof {
    width: 75%;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .prof_wrap .prof_row .col_prof {
    width: 95%;
  }
}

.prof_wrap .prof_row .col_prof .list {
  display: flex;
}

.prof_wrap .prof_row .col_prof .prof_img {
  width: 100%;
}

.prof_wrap .prof_row .col_prof .prof_title {
  font-family: Open Sans, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 25px;
}

.prof_wrap .prof_row .col_prof .prof_text {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 15px;
}

.prof_wrap .prof_row .col_prof .count {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #144cb7;
  margin-left: 25px;
  margin-right: 20px;
}

.come-us {
  background-color: #111212;
  position: relative;
}

.come-us .come-us_container {
  margin: auto;
}

.come-us .come-us_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.come-us .come-us_container .come-us_contact {
  padding-top: 30px;
  margin-left: 20px;
  margin-top: 30px;
}

@media only screen and (max-width: 750px) {
  .come-us_contact {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
}

.come-us_contact .contact-wrap .text-ithem {
  font-family: Open Sans, sans-serif;
  color: #ffffff;
  line-height: 36px;
}

@media only screen and (max-width: 750px) {
  .come-us_contact .contact-wrap .text-ithem {
    line-height: 25px;
    text-align: center;
  }
}

.come-us_contact .contact-wrap .ithem_22 {
  font-size: 22px;
  text-transform: uppercase;
}

@media only screen and (max-width: 750px) {
  .come-us_contact .contact-wrap .ithem_22 {
    font-size: 18px;
  }
}

.come-us_contact .contact-wrap .ithem_18_n {
  font-size: 18px;
}

@media only screen and (max-width: 750px) {
  .come-us_contact .contact-wrap .ithem_18_n {
    font-size: 16px;
  }
}

.come-us_contact .contact-wrap .ithem_18_ttu {
  font-size: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 750px) {
  .come-us_contact .contact-wrap .ithem_18_ttu {
    font-size: 16px;
  }
}

.come-us_contact .map_content {
  position: absolute;
  height: 560px;
  width: 57%;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 750px) {
  .come-us_contact .map_content {
    position: relative;
    height: 300px;
    width: 98%;
    margin-top: 25px;
  }
}

.antigrav {
  background-image: url(../img/antigravii_bg.png);
}

.he-order_bg {
  background-image: url(../img/heorder_bg.png);
}

.he-order {
  background-color: #111212;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
}

.he-order .he-order_container {
  margin: auto;
}

.he-order .he-order_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.he-order .he-order_container .us-col_subtitle {
  max-width: 850px;
  padding: 0 10px;
  width: 100%;
  margin: auto;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}

.he-order .he-order_container .order-slide {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 80px;
  position: relative;
}

.he-order .he-order_container .btn-wont {
  padding: 12px 20px;
  text-align: center;
  max-width: 420px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #0b327d;
  margin: auto;
  margin-top: 65px;
  cursor: pointer;
}

.prev-btn {
  left: -60px;
  z-index: 99;
}

@media only screen and (max-width: 750px) {
  .prev-btn {
    left: -35px;
  }
}

.next-btn {
  right: -60px;
  z-index: 99;
}

@media only screen and (max-width: 750px) {
  .next-btn {
    right: -35px;
  }
}

.slide-btn {
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
  color: #595a5b;
  font-size: 40px;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .slide-btn {
    top: 60%;
  }
}

.order-slide .slide-wrap .slide-ithem {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .order-slide .slide-wrap .slide-ithem {
    display: flex;
    flex-direction: column;
  }
}

.slide-ithem .photo-slide .img-slide {
  height: 275px;
}

@media only screen and (max-width: 930px) {
  .slide-ithem .photo-slide .img-slide {
    width: 135px;
    height: auto;
  }
}

@media only screen and (max-width: 750px) {
  .slide-ithem .photo-slide .img-slide {
    margin-bottom: 35px;
  }
}

.slide-ithem .content-slide:before {
  content: url("/img/arr-slide.png");
  width: 30px;
  height: 30px;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 750px) {
  .slide-ithem .content-slide:before {
    top: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    content: url("/img/arr-slideUp.png");
  }
}

.slide-ithem .content-slide {
  background-color: rgba(10, 13, 16, 0.6);
  padding: 30px;
  border-radius: 5px;
  margin-left: 60px;
  position: relative;
}

@media only screen and (max-width: 945px) {
  .slide-ithem .content-slide {
    margin-left: 35px;
  }
}

@media only screen and (max-width: 750px) {
  .slide-ithem .content-slide {
    margin-left: 0;
  }
}

@media only screen and (max-width: 550px) {
  .slide-ithem .content-slide {
    padding: 10px;
  }
}

.slide-ithem .content-slide .slide-title {
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  line-height: 36px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 25px;
}

@media only screen and (max-width: 550px) {
  .slide-ithem .content-slide .slide-title {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.slide-ithem .content-slide .slide-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 30px;
}

.slide-ithem .content-slide .slide-autor {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #858585;
  text-align: right;
}

.btn-s {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding: 15px 0;
  cursor: pointer;
}

.index {
  background: url(../img/home-main_bg.png);
}

.nano {
  background: url(../img/nano-main_bg.png);
}

.bron {
  background: url(../img/bron-main_bg.png);
}

.tonirovka {
  background: url(../img/tonirovka-main_bg.png);
}

.polirovka {
  background: url(../img/polirivka-main_bg.png);
}

.himchistka {
  background: url(../img/himchistka-main_bg.png);
}

.antigravii {
  background: url(../img/antigravii-main_bg.png);
}

.home-main {
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-top: 200px;
}

.home-main .home_content {
  max-width: 970px;
}

.home-main .nano_content {
  max-width: 1170px;
}

.home-main .content-wrap {
  width: 100%;
  padding: 0 20px;
  margin: auto;
  padding-bottom: 70px;
}

.home-main .content-wrap .back_home {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 35px;
  display: block;
}

@media only screen and (max-width: 550px) {
  .home-main .content-wrap .back_home {
    margin-top: 100px;
  }
}

.home-main .content-wrap .back_home img {
  margin-right: 10px;
}

.home-main .content-wrap .home-title {
  font-family: Open Sans, sans-serif;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 365px;
}

@media only screen and (max-width: 768px) {
  .home-main .content-wrap .home-title {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 550px) {
  .home-main .content-wrap .home-title {
    margin-top: 140px;
    margin-bottom: 45px;
    font-size: 27px;
  }
}

.home-main .content-wrap .nano-title {
  font-family: Open Sans, sans-serif;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 60px;
}

@media only screen and (max-width: 550px) {
  .home-main .content-wrap .nano-title {
    margin-top: 35px;
    margin-bottom: 45px;
    font-size: 27px;
  }
}

.nav-button_wrap .button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .nav-button_wrap .button-row {
    display: none;
  }
}

.nav-button_wrap .button-row .btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 45px;
  background: rgba(18, 18, 18, 0.6);
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  transform: skew(-20deg);
  margin-bottom: 20px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.nav-button_wrap .button-row .btn-nav:hover .title {
  font-size: 17px;
}

.nav-button_wrap .button-row .btn-nav .title {
  font-family: Open Sans, sans-serif;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg);
}

.nav-button_wrap .button-callbac {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 650px) {
  .nav-button_wrap .button-callbac {
    flex-direction: column;
  }
}

.nav-button_wrap .button-callbac .btn-blu {
  background-color: #0b327d;
  width: 48%;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 650px) {
  .nav-button_wrap .button-callbac .btn-blu {
    margin: auto;
    margin-bottom: 15px;
    max-width: 420px;
    width: 100%;
  }
}

.nav-button_wrap .button-callbac .btn-ops {
  border: 1px solid #ffffff;
  width: 48%;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 650px) {
  .nav-button_wrap .button-callbac .btn-ops {
    margin: auto;
    max-width: 420px;
    width: 100%;
  }
}

.list_home {
  margin-left: 30px;
}

@media only screen and (max-width: 550px) {
  .list_home {
    margin-left: 0;
  }
}

.list_home .button-call {
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
}

@media only screen and (max-width: 650px) {
  .list_home .button-call {
    flex-direction: column;
  }
}

.list_home .button-call .btn-blu {
  background-color: #0b327d;
  width: 420px;
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 15px;
}

@media only screen and (max-width: 650px) {
  .list_home .button-call .btn-blu {
    margin: auto;
    margin-bottom: 15px;
    max-width: 420px;
    width: 100%;
  }
}

.list_home .ithem {
  display: flex;
  margin-bottom: 20px;
}

.list_home .ithem .check {
  margin-right: 30px;
  width: 25px;
  height: 25px;
}

.list_home .ithem .ithem_list {
  font-family: Open Sans, sans-serif;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  width: 96%;
}

.how-pay {
  background-color: #1a1a1a;
}

.how-pay .how-pay_container {
  margin: auto;
}

.how-pay .how-pay_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.how-pay .how-pay_container .pay-table {
  margin-top: 60px;
}

@media only screen and (max-width: 825px) {
  .how-pay .how-pay_container .pay-table {
    margin-top: 35px;
  }
}

.how-pay .how-pay_container .btn-order {
  padding: 12px 20px;
  text-align: center;
  max-width: 420px;
  font-family: Open Sans,sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background-color: #0b327d;
  margin: 65px auto auto;
  cursor: pointer;
}

.pay-table .table-col {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
}

@media only screen and (max-width: 825px) {
  .pay-table .table-col {
    flex-direction: column;
  }
}

.pay-table .table-col .left-col {
  width: 40%;
  padding: 5px;
}

@media only screen and (max-width: 825px) {
  .pay-table .table-col .left-col {
    width: 100%;
  }
}

.pay-table .table-col .left-col .title-col {
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  line-height: 37px;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 550px) {
  .pay-table .table-col .left-col .title-col {
    font-size: 18px;
  }
}

.pay-table .table-col .left-col .subtitle-col {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  color: #fff;
}

.pay-table .table-col .right-col {
  width: 60%;
  display: flex;
}

@media only screen and (max-width: 825px) {
  .pay-table .table-col .right-col {
    width: 100%;
  }
}

.pay-table .table-col .right-col .dflex {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pay-table .table-col .right-col .col-ithem {
  width: 25%;
  padding: 5px 20px;
}

@media only screen and (max-width: 420px) {
  .pay-table .table-col .right-col .col-ithem {
    padding: 5px;
  }
}

.pay-table .table-col .right-col .col-ithem .title-ithem_hidden {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #969696;
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

@media only screen and (max-width: 825px) {
  .pay-table .table-col .right-col .col-ithem .title-ithem_hidden {
    display: block;
  }
}

@media only screen and (max-width: 550px) {
  .pay-table .table-col .right-col .col-ithem .title-ithem_hidden {
    font-size: 14px;
  }
}

.pay-table .table-col .right-col .col-ithem .title-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 550px) {
  .pay-table .table-col .right-col .col-ithem .title-ithem {
    font-size: 14px;
  }
}

.pay-table .table-col .right-col .col-ithem .subtitle-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  color: #848484;
}

@media only screen and (max-width: 925px) {
  .pay-table .table-col .right-col .col-ithem .subtitle-ithem {
    display: none;
  }
}

.pay-table .gley-line {
  border-bottom: 1px solid #434343;
}

@media only screen and (max-width: 825px) {
  .pay-table .gley-line {
    display: none;
  }
}

.our-services {
  background-color: #1a1a1a;
}

.our-services .services-container {
  padding: 50px 0;
  margin: auto;
}

.our-services .services-container .section-title {
  max-width: 360px;
  margin: auto;
  text-align: center;
}

.service-row {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
}

.service-row .col-service {
  background-color: #131414;
  width: 31%;
  padding-bottom: 40px;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.service-row .col-service:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 950px) {
  .service-row .col-service {
    width: 47%;
  }
}

@media only screen and (max-width: 750px) {
  .service-row .col-service {
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .service-row .col-service {
    padding: 20px 15px;
    width: 95%;
  }
}

.service-row .col-service .service-img {
  width: 100%;
}

.service-row .col-service .services-ithem {
  padding: 0 10px;
}

.service-row .col-service .services-ithem .service-title {
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}

.service-row .col-service .services-ithem .service-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.our-work {
  background-color: #1a1a1a;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url(../img/our-work_bg.png);
}

.our-work .our-work_container {
  margin: auto;
}

.our-work .our-work_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.our-work .our-work_container .our-work_row {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .col-hid {
    display: none;
  }
}

.our-work_row .our-work_col {
  width: 31%;
  margin-bottom: 35px;
  margin-left: 12px;
  margin-right: 10px;
  position: relative;
  z-index: 3;
}

.our-work_row .our-work_col:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 980px) {
  .our-work_row .our-work_col {
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .our-work_row .our-work_col {
    width: 95%;
    margin: auto;
    margin-bottom: 15px;
  }
}

.our-work_row .our-work_col .our-work_img {
  width: 100%;
  height: 100%;
}

.our-work_row .our-work_col .our-work_img img {
  width: 100%;
}

.our-work_row .our-work_col .zoom {
  position: absolute;
  z-index: 4;
  bottom: 0;
  right: 0;
  width: 35px;
  height: 35px;
  display: block;
  background-color: #0b327d;
  cursor: pointer;
  display: none;
}

.our-work_row .our-work_col .zoom .fas {
  font-weight: 900;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.sing-up {
  background-color: #111212;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url(../img/sing-up_bg.png);
}

.sing-up .sing-up_container {
  padding-bottom: 0;
  margin: auto;
}

.sing-up .sing-up_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.sing-up .sing-up_container .sing-up_row {
  display: flex;
  margin-top: 15px;
  padding: 0 15px;
}

@media only screen and (max-width: 550px) {
  .sing-up .sing-up_container .sing-up_row {
    padding: 0;
  }
}

.sing-up_row .sing_up-img {
  width: 50%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .sing-up_row .sing_up-img {
    width: 1%;
  }
}

.sing-up_row .sing_up-img img {
  margin-bottom: -4px;
}

@media only screen and (max-width: 900px) {
  .sing-up_row .sing_up-img img {
    opacity: 0.5;
    z-index: 2;
  }
}

@media only screen and (max-width: 550px) {
  .sing-up_row .sing_up-img img {
    display: none;
  }
}

.sing-up_row .sing_up-col {
  width: 50%;
  margin-top: 40px;
}

@media only screen and (max-width: 900px) {
  .sing-up_row .sing_up-col {
    z-index: 3;
    width: 75%;
    margin: auto;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 550px) {
  .sing-up_row .sing_up-col {
    margin-bottom: 35px;
    width: 95%;
  }
}

.sing-up_row .sing_up-col .title-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 35px;
}

@media only screen and (max-width: 550px) {
  .sing-up_row .sing_up-col .title-ithem {
    font-size: 22px;
    line-height: 26px;
  }
}

.sing-up_row .sing_up-col .text-ithem {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 27px;
}

.sing-up_row .sing_up-col .form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sing-up_row .sing_up-col .form .form-inp {
  height: 50px;
  text-align: center;
  font-size: 16px;
  color: #898989;
  margin-bottom: 20px;
}

.sing-up_row .sing_up-col .form .form-sub {
  height: 50px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #0b327d;
}

.the-trust {
  background-color: #0e0e0e;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url(../img/our-work_bg.png);
}

.the-trust .the-trust_contaner {
  margin: auto;
}

.the-trust .the-trust_contaner .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.the-trust .the-trust_contaner .row-trust {
  padding: 40px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 550px) {
  .the-trust .the-trust_contaner .row-trust {
    padding: 40px;
  }
}

.row-trust .col-trust {
  width: 32%;
  cursor: pointer;
}

.row-trust .col-trust:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 850px) {
  .row-trust .col-trust {
    width: 49%;
  }
}

@media only screen and (max-width: 550px) {
  .row-trust .col-trust {
    width: 100%;
    margin-bottom: 15px;
  }
}

.row-trust .col-trust img {
  width: 100%;
}

.we-doing {
  background-color: #1a1a1a;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url(../img/we-bg.png);
}

.we-doing .we-doing_container {
  padding: 50px 0;
  margin: auto;
}

@media only screen and (max-width: 1300px) {
  .we-doing .we-doing_container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    max-width: inherit;
  }
}

.we-doing .we-doing_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.we-doing .we-doing_container .btn-call-us {
  padding: 12px 20px;
  text-align: center;
  max-width: 420px;
  font-family: Open Sans,sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background-color: #0b327d;
  margin: 65px auto auto;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .we-doing .we-doing_container .btn-call-us {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.we-doing .we-doing_container .we-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  width: 72%;
  margin: auto;
  margin-top: 40px;
}

.we-doing .we-doing_container .animation-wrap {
  height: 720px;
  position: relative;
  margin-top: 40px;
}

.we-doMob {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .we-doMob {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }
  .we-doMob img {
    width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .animation-wrap {
    display: none;
  }
}

.cars {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%);
  z-index: 90;
}

.elips {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 89;
}

.elips-light {
  position: absolute;
  bottom: 0;
  left: 30%;
  transform: translate(-50%);
  z-index: 91;
}

.car-stop {
  position: absolute;
  bottom: 135px;
  z-index: 92;
  left: 75px;
}

.points {
  position: absolute;
  z-index: 94;
}

#we_001 {
  left: 100px;
  bottom: 115px;
}

#we_002 {
  bottom: 235px;
  left: 180px;
}

#we_003 {
  bottom: 250px;
  left: 430px;
}

#we_004 {
  bottom: 165px;
  left: 555px;
}

#we_005 {
  right: 445px;
  bottom: 245px;
}

#we_006 {
  right: 200px;
  bottom: 205px;
}

#we_007 {
  right: 100px;
  bottom: 160px;
}

.animation-cont {
  width: 175px;
  position: absolute;
  z-index: 93;
}

.animation-cont img {
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.animation-cont .cont-text {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

#we_01 {
  top: 210px;
  left: 8px;
}

#we_02 {
  top: 95px;
  left: 200px;
}

#we_03 {
  top: 235px;
  left: 350px;
}

#we_04 {
  top: 62px;
  left: 483px;
}

#we_05 {
  top: 192px;
  right: 388px;
}

#we_06 {
  top: 105px;
  right: 215px;
}

#we_07 {
  top: 201px;
  right: 15px;
}

.your-benefit {
  background-color: #0b327d;
}

.your-benefit .your-benefit_container {
  margin: auto;
}

.your-benefit .your-benefit_container .section-title {
  width: auto;
  margin: auto;
  text-align: center;
}

.your-benefit .your-benefit_container .benefit-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 35px;
}

.your-benefit .your-benefit_container .section-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  width: 75%;
  text-align: center;
  display: block;
  margin: auto;
  margin-bottom: 40px;
}

@media only screen and (max-width: 750px) {
  .your-benefit .your-benefit_container .section-subtitle {
    width: 85%;
  }
}

.your-benefit .your-benefit_container .btn-benefit {
  padding: 12px 10px;
  text-align: center;
  max-width: 420px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #ffffff;
  cursor: pointer;
  margin: auto;
  display: block;
}

.benefit-row .benefit-col {
  width: 30.5%;
  padding: 25px 40px;
  background-color: #24478a;
  text-align: center;
  margin-bottom: 30px;
  margin-left: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.benefit-row .benefit-col:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 950px) {
  .benefit-row .benefit-col {
    width: 47%;
  }
}

@media only screen and (max-width: 750px) {
  .benefit-row .benefit-col {
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .benefit-row .benefit-col {
    padding: 20px 15px;
    width: 95%;
  }
}

.benefit-row .benefit-col .benefit-ico {
  width: 125px;
  margin: auto;
}

.benefit-row .benefit-col .benefit-title {
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}

.benefit-row .benefit-col .benefit-subtitle {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.thanks {
  height: 100vh;
  background-position-y: center !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: url(../img/thanks-main_bg.png);
  background-color: #1a1a1a;
}

.thanks .thanks-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thanks .thanks-wrap .thank_you {
  font-family: Open Sans, sans-serif;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 35px;
}

@media only screen and (max-width: 550px) {
  .thanks .thanks-wrap .thank_you {
    margin-top: 140px;
    font-size: 27px;
  }
}

.thanks .thanks-wrap .home {
  background-color: #0b327d;
  width: 420px;
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 15px;
  margin: auto;
}

@media only screen and (max-width: 650px) {
  .thanks .thanks-wrap .home {
    margin: auto;
    margin-bottom: 15px;
    max-width: 420px;
    width: 100%;
  }
}

.thanks .footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
