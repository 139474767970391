

.antigrav {
  background-image: url(../img/antigravii_bg.png); }

.he-order_bg {
  background-image: url(../img/heorder_bg.png); }

.he-order {
  background-color: #111212;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  overflow: hidden;

  .he-order_container {
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .us-col_subtitle {
      max-width: 850px;
      padding: 0 10px;
      width: 100%;
      margin: auto;
      @include font;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 10px; }

    .order-slide {
      display: flex;
      align-items: center;
      width: 80%;
      margin: auto;
      margin-top: 80px;
      position: relative; }

    .btn-wont {
      padding: 12px 20px;
      text-align: center;
      max-width: 420px;
      @include font;
      font-size: 16px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #0b327d;
      margin: auto;
      margin-top: 65px;
      cursor: pointer; } } }

.prev-btn {
  left: -60px;
  z-index: 99;
  @include r(750) {
    left: -35px; } }

.next-btn {
  right: -60px;
  z-index: 99;
  @include r(750) {
    right: -35px; } }

.slide-btn {
  position: absolute;
  top: 45%;
  transform: translate(0,-50%);
  color: #595a5b;
  font-size: 40px;
  cursor: pointer;
  @include r(750) {
    top: 60%; } }

.order-slide {

  .slide-wrap {

    .slide-ithem {
      display: flex;
      align-items: center;

      @include r(750) {
        display: flex;
        flex-direction: column; } } } }


.slide-ithem {
  .photo-slide {

    .img-slide {
      height: 275px;
      @include r(930) {
        width: 135px;
        height: auto; }

      @include r(750) {
        margin-bottom: 35px; } } }

  .content-slide:before {
    content: url("/img/arr-slide.png");
    width: 30px;
    height: 30px;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translate(0, -50%);
    @include r(750) {
      top: -25px;
      left: 50%;
      transform: translate(-50%, 0);
      content: url("/img/arr-slideUp.png"); } }

  .content-slide {
    background-color: rgba(10, 13, 16, 0.6);
    padding: 30px;
    border-radius: 5px;
    margin-left: 60px;
    position: relative;
    @include r(945) {
      margin-left: 35px; }

    @include r(750) {
      margin-left: 0; }

    @include r(550) {
      padding: 10px; }

    .slide-title {
      @include font;
      font-size: 22px;
      line-height: 36px;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 25px;

      @include r(550) {
        font-size: 18px;
        margin-bottom: 14px; } }

    .slide-subtitle {
      @include font;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 30px; }

    .slide-autor {
      @include font;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: #858585;
      text-align: right; } } }


