.sing-up {
  background-color: #111212;
  background-position-y: center!important;
  background-position-x: center!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-image: url(../img/sing-up_bg.png);

  .sing-up_container {
    padding-bottom: 0;
    margin: auto;

    .section-title {
      width: auto;
      margin: auto;
      text-align: center; }

    .sing-up_row {
      display: flex;
      margin-top: 15px;
      padding: 0 15px;
      @include r(550) {
        padding: 0; } } } }

.sing-up_row {
  .sing_up-img {
    width: 50%;
    display: flex;
    justify-content: center;

    @include r(900) {
      width: 1%; }

    img {
      margin-bottom: -4px;

      @include r(900) {
        opacity: 0.5;
        z-index: 2; }

      @include r(550) {
        display: none; } } }

  .sing_up-col {
    width: 50%;
    margin-top: 40px;

    @include r(900) {
      z-index: 3;
      width: 75%;
      margin: auto;
      margin-top: 40px; }

    @include r(550) {
      margin-bottom: 35px;
      width: 95%; }

    .title-ithem {
      @include font;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 35px;

      @include r(550) {
        font-size: 22px;
        line-height: 26px; } }

    .text-ithem {
      @include font;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-bottom: 27px; }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .form-inp {
        height: 50px;
        text-align: center;
        font-size: 16px;
        color: #898989;
        margin-bottom: 20px; }

      .form-sub {
        height: 50px;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #0b327d; } } } }

